@import '@/styles/tools/responsive';

.button {
    // height: 100%;
    cursor: pointer;
    border-radius: var(--border-radius-small);
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.5em;
    padding: 1em 2em;
    // font-weight: bold;

    @include breakpoint(mobile) {
        padding: 0.5em 1em;
    }

    .icon {
        // width: 10px;
        // align-self: center;
    }

    &__primary {
        border: 2px solid var(--color-primary);
        background-color: var(--color-primary);
        color: var(--color-white);

        &:hover,
        &:focus {
            opacity: 0.9;
        }
    }

    &__secondary {
        border: 2px solid var(--color-secondary);
        background-color: var(--color-secondary);
        color: var(--color-primary);

        &:hover,
        &:focus {
            opacity: 0.9;
        }
    }

    &__terciary {
        border: 2px solid var(--color-black);
        background-color: transparent;
        box-shadow: var(--color-button-terciary-gradient);
        color: var(--color-button-text);
        &:hover,
        &:focus {
            opacity: 0.9;
        }
    }

    &__disabled {
        opacity: 0.5;
        cursor: not-allowed;
        pointer-events: none;
        color: var(--color-white);
    }
    &__small {
        font-size: var(--font-button-small);
    }
    &__medium {
        font-size: var(--font-button-medium);
    }
    &__large {
        font-size: var(--font-button-large);
    }
}
