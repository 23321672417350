.formContainer {
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
    // display: flex;
    // flex-direction: column;
    // align-items: center;
    // justify-content: center;

    /* * {
        font-family: 'Basier' !important;
        ::placeholder {
            color: var(--color-secondary) !important;
        }

        ::-ms-input-placeholder {
            color: var(--color-secondary) !important;
        }
    } */

    .block {
        /* button {
            color: var(--color-white) !important;
            background: var(--color-secondary);
        } */

        textarea {
            // color: var(--color-primary) !important;
            font-size: var(--font-medium) !important;
            background: rgba(var(--color-secondary-rgb), 0.2) !important;
            box-shadow: var(--color-secondary) 0px 1px !important;
        }

        input {
            // color: var(--color-primary) !important;
            box-shadow: var(--color-secondary) 0px 1px !important;
        }
    }
}
