{
    "translation": {
        "input_placeholder": "Type your answer here",
        "email_placeholder": "Type your email address",
        "completed": "completed",
        "required": "This field is required!",
        "numbers": "Numbers only",
        "invalid_email": "Invalid email!",
        "invalid_url": "Invalid URL!",
        "greater_number": "Please enter a number greater than",
        "submit": "Submit",
        "startup": "I am a startup",
        "investor": "I am an investor",
        "name": "What's your name?",
        "startup_name": "What's the name of your startup?",
        "founders": "Write the name(s) of the founder(s) and his/her/their position(s)",
        "summary": "Tell us briefly about {{field:startup}}, what problem you solve and how you do it",
        "website": "What's the startup's website? If you don't have one yet, please enter the startup's LinkedIn page or leave the field empty.",
        "nexen": "Don't worry if you don't have one yet, we are working with Nexen Capital to provide this service to our network",
        "email": "What's your email address?",
        "deck": "Upload the deck of {{field:startup}}",
        "choose_file": "Choose file",
        "or": "or",
        "drag_here": "Drag and drop here",
        "max_file_size": "Max file size is",
        "sector": "What's the startup's sector?",
        "business_model": "What's the startup's business model?",
        "stage": "What's the startup's stage?",
        "technetium": "What are you looking for in Technetium?",
        "thank_you": "Thank you for your submission! We will contact you soon",
        "mission": "Our mission is",
        "democratize": "to democratize the entrepreneurship",
        "venture_dev": "With our Venture Dev model, we invest in technology startups and high-potential founders as skin in the game partners, specialising in the selection, financing and support of the CTO"
    }
}
