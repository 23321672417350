.container {
    display: flex;
    gap: 0.2em;

    button {
        background: none;
        border: none;
        cursor: pointer;
        width: 40px;
        height: 30px;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            // border-radius: 50%;
        }
    }
}
