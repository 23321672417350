{
    "translation": {
        "input_placeholder": "Escriba aquí su respuesta",
        "email_placeholder": "Escriba aquí su email",
        "completed": "completado",
        "required": "Este campo es obligatorio",
        "numbers": "Sólo números",
        "invalid_email": "Correo electrónico no válido",
        "invalid_url": "URL no válida",
        "greater_number": "Por favor, introduzca un número mayor que",
        "submit": "Enviar",
        "startup": "Soy una startup",
        "investor": "Soy un inversor",
        "name": "Dinos tu nombre.",
        "startup_name": "¿Cuál es el nombre de la startup?",
        "founders": "Escribe el nombre del fundador o fundadores y su(s) cargo(s)",
        "summary": "Cuéntanos brevemente sobre {{field:startup}}, qué problema resolvéis y cómo lo hacéis",
        "website": "¿Cuál es la web de la startup? Si no tiene una aún, por favor introduce la página de LinkedIn de la startup o deja el campo vacío",
        "nexen": "No te preocupes si aún no tienes uno, estamos trabajando con Nexen Capital para ofrecer este servicio a nuestra red",
        "email": "¿Cuál es tu correo electrónico?",
        "deck": "Sube el deck de {{field:startup}}",
        "choose_file": "Elige un archivo",
        "or": "o",
        "drag_here": "Arrastra aquí",
        "max_file_size": "El tamaño máximo del archivo es",
        "sector": "¿Cuál es el sector de la startup?",
        "business_model": "¿Cuál es el modelo de negocio de la startup?",
        "stage": "¿En qué etapa se encuentra la startup?",
        "technetium": "¿Qué estás buscando en Technetium?",
        "thank_you": "¡Gracias por tu envío! Contactaremos contigo pronto",
        "mission": "Nuestra misión es",
        "democratize": "democratizar el emprendimiento",
        "venture_dev": "Con nuestro modelo Venture Dev, invertimos en startups tecnológicas y fundadores de alto potencial como socios skin in the game, especializados en la selección, financiación y acompañamiento del CTO"
    }
}
