@import '@/styles/tools/responsive';

.container {
    width: 80%;
    // height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    // justify-content: center;
    // gap: 0;
    padding-top: 10vh;
}

.logoContainer {
    width: 100%;
    max-width: 600px;
    // display: flex;
    // flex-direction: row;
    // align-items: center;
    // justify-content: center;

    img {
        width: 100%;
    }
}

.textContainer {
    width: 100%;
    text-align: center;
    color: var(--color-primary);
    padding-top: 3em;
    padding-bottom: 2em;

    h1 {
        font-size: var(--font-extra-large);

        /* @include breakpoint(smallTablet) {
            font-size: var(--font-large);
        } */
    
        @include breakpoint(mobile) {
            font-size: var(--font-large);
        }
    }
}

.formButtonsContainer {
    width: 100%;
    max-width: 400px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 1em;
    min-height: 3.5em;

    Button {
        flex: 1;
        height: 100%;
    }
}

.footer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: 0;
    width: 100%;
    margin-bottom: 15px;

    a {
        width: 3em;
        height: 3em;
        cursor: pointer;

        svg {
            width: 100%;
            height: auto;
        }
    }
}
