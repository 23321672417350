import classNames from 'classnames';
import { ButtonProps } from './Button.types';
import styles from './Button.module.scss';

const Button = ({
    children,
    onClick,
    disabled,
    type = 'button',
    form,
    variant,
    size,
    IconLeft,
    IconRight
}: ButtonProps) => {
    return (
        <button
            disabled={disabled}
            onClick={onClick}
            className={classNames(
                styles.button,
                styles[`button__${variant}`],
                styles[`button__${size}`],
                disabled ? styles['button__disabled'] : ''
            )}
            form={form}
            type={type}
        >
            {IconLeft && <IconLeft className={styles.icon}></IconLeft>}
            {children} {IconRight && <IconRight className={styles.icon}></IconRight>}
        </button>
    );
};

export default Button;
