import styles from './LeadForm.module.scss';
import { useEffect } from 'react';
import { Form, useFormAnswers } from "@quillforms/renderer-core";
import "@quillforms/renderer-core/build-style/style.css";
import { registerCoreBlocks } from "@quillforms/react-renderer-utils";
import request from '@/utils/helpers/apiResponse.helper';
import useTranslator from '@/utils/translator/translator';
import { useLocation, useParams } from 'react-router-dom';
import "./file-block";
import { BUSINESS_MODELS, STAGES } from '@/utils/constants/lists';

registerCoreBlocks();


const LeadForm = () => {
    const { lang } = useParams();
    const location = useLocation();

    const { t, setLang } = useTranslator();

    const formAnswers = useFormAnswers();

    const formId = parseInt(Math.random().toString(36).substring(2, 15));
    const page = location.pathname.split('/').slice(-1)[0];

    const sendToNotion = async () => {
        await request({
            url: page === 'startup' ? '/startup-form' : '/investor-form',
            method: 'POST',
            data: formAnswers
        });
    };

    useEffect(() => {
        setLang(lang);
    }, [lang]);

    return (
        <div className={styles.formContainer}>
            <Form
                formId={formId}
                formObj={{
                    customCSS: `
                        /* Removes the Quillforms navigation */
                        .renderer-core-progress-bar { 
                            background: rgba(var(--color-secondary-rgb), 0.2); 
                            .renderer-core-html-parser { color: var(--color-primary)!important; }
                        }

                        .multipleChoice__optionWrapper {
                            background-color: var(--color-primary);
                            color: var(--color-white);

                            
                        }

                        .selected {
                            background-color: var(--color-secondary) !important;
                        }

                        .multipleChoice__optionKey {
                            display: none !important;
                        }
                    `,
                    settings: {
                        disableProgressBar: false, // Default: false
                        disableWheelSwiping: false, // Default: false
                        disableNavigationArrows: false, // Default: false
                        showQuestionsNumbers: false, // Default: true
                        showLettersOnAnswers: true, // Default: true

                    },
                    theme: {
                        font: 'Basier',
                        // backgroundColor: string;
                        // backgroundImage: string;
                        questionsColor: 'var(--color-primary)',
                        answersColor: 'var(--color-primary)',
                        buttonsFontColor: 'var(--color-white)',
                        buttonsBgColor: 'var(--color-secondary)',
                        errorsFontColor: 'var(--color-primary)',
                        errorsBgColor: '#ffcccc',
                        progressBarFillColor: 'var(--color-secondary)',
                        progressBarBgColor: 'var(--color-white)',
                    },
                    messages: {
                        'block.shortText.placeholder': t("input_placeholder") ?? "Type your answer here",
                        'block.longText.placeholder': t("input_placeholder") ?? "Type your answer here",
                        'block.email.placeholder': t("email_placeholder") ?? "Type your email here",
                        'block.defaultThankYouScreen.label': t("thank_you")?? "Thank you for your submission! We will contact you soon",
                        'label.progress.percent': `{{progress:percent}}% ${t("completed")}`,
                        'label.errorAlert.required': t("required") ?? "This field is required!",
                        'label.errorAlert.number': t("numbers") ?? "Numbers only!",
                        'label.errorAlert.email': t("invalid_email") ?? "Invalid email!",
                        'label.errorAlert.url': t("invalid_url") ?? "Invalid url!",
                        'label.errorAlert.minNum': `${t("greater_number")} {{attribute:min}}`,
                        'label.submitBtn': t("submit") ?? "Submit",
                    },
                    blocks: page === 'startup' ? [
                        /* {
                            name: "welcome-screen",
                            id: "jg1401r",
                            attributes: {
                                label: "Welcome!",
                                description: "Please, tell us more about you and your team",
                                attachment: {
                                    type: "image",
                                    url:
                                        technetium
                                }
                            }
                        }, */
                        {
                            name: "short-text",
                            id: "startup",
                            attributes: {
                                classnames: styles.block,
                                required: true,
                                label: t("startup_name") ?? "What's the name of your startup?",
                            }
                        },
                        {
                            name: "long-text",
                            id: "founders",
                            attributes: {
                                classnames: styles.block,
                                required: true,
                                label: `${t("founders") ?? "Write the name(s) of the founder(s) and his/her/their position(s)"}.`,
                            }
                        },
                        {
                            name: "long-text",
                            id: "summary",
                            attributes: {
                                classnames: styles.block,
                                required: true,
                                label: `${t("summary") ?? "Tell us briefly about {{field:startup}}, what problem you solve and how you do it"}.`,
                            }
                        },
                        {
                            name: "website",
                            id: "website",
                            attributes: {
                                classnames: styles.block,
                                required: false,
                                label: t("website") ?? "What's the startup's website? If you don't have one yet, please enter the startup's LinkedIn page or leave the field empty."
                            }
                        },
                        /* {
                            name: "file-upload",
                            id: "deck",
                            attributes: {
                                classnames: styles.block,
                                required: false,
                                maxFileSize: 10,
                                label: `${t("deck") ?? "Upload the deck of {{field:startup}}"}.`,
                                description: `${t("nexen") ?? "Don't worry if you don't have one yet, we are working with Nexen Capital to provide this service to our network"}.`
                            }
                        }, */
                        /* {
                            name: "multiple-choice",
                            id: "sector",
                            attributes: {
                                choices: SECTORS,
                                multiple: true,
                                required: true,
                                label: t("sector") ?? "What's the startup's sector?",
                            }
                        }, */
                        {
                            name: "multiple-choice",
                            id: "business-model",
                            attributes: {
                                choices: BUSINESS_MODELS,
                                multiple: true,
                                required: true,
                                label: t("business_model") ?? "What's the startup's business model?",
                            }
                        },
                        {
                            name: "multiple-choice",
                            id: "stage",
                            attributes: {
                                choices: STAGES,
                                multiple: false,
                                required: true,
                                label: t("stage") ?? "What's the startup's stage?",
                            }
                        },
                        {
                            name: "long-text",
                            id: "technetium",
                            attributes: {
                                classnames: styles.block,
                                required: false,
                                label: t("technetium") ?? "What are you looking for in Technetium?",
                            }
                        },
                        {
                            name: "email",
                            id: "email",
                            attributes: {
                                classnames: styles.block,
                                required: true,
                                label: t("email") ?? "What's your email address?",
                            }
                        },
                    ] : [
                        {
                            name: "short-text",
                            id: "name",
                            attributes: {
                                classnames: styles.block,
                                required: true,
                                label: t("name") ?? "What's your name?"
                            }
                        },
                        {
                            name: "email",
                            id: "email",
                            attributes: {
                                classnames: styles.block,
                                required: true,
                                label: t("email") ?? "What's your email address?",
                            }
                        },
                    ],
                }}
                onSubmit={async (data, { completeForm, setIsSubmitting, goToBlock, setSubmissionErr }) => {
                    await sendToNotion();
                    setTimeout(() => {
                        setIsSubmitting(false);
                        completeForm();
                    }, 500);
                }}
            />
        </div>
    );
};

export default LeadForm;