import { ButtonSizes, ButtonVariants } from '@/components/Button/Button.types';
import styles from './UnderConstruction.module.scss';
import colour_letters from '@/assets/images/colour_letters.png';
import { useNavigate } from 'react-router-dom';
import Button from '@/components/Button/Button';
import LinkedinIcon from '@/assets/icons/linkedin.svg';
import useTranslator from '@/utils/translator/translator';
// import ChangeLangsButtons from '@/components/ChangeLangButtons/ChangeLangButtons';

const UnderConstruction = () => {
    const navigate = useNavigate();

    const { t, lang } = useTranslator();

    return (
        <div className={styles.container}>
            <div className={styles.logoContainer}>
                {/* <TechnetiumIcon /> */}
                <img src={colour_letters} alt="Company Logo" />
            </div>
            <div className={styles.textContainer}>
                <h1>{t('venture_dev')}.</h1>
            </div>
            <div className={styles.formButtonsContainer}>
                <Button variant={ButtonVariants.PRIMARY} size={ButtonSizes.MEDIUM} onClick={() => navigate(`/${lang}/startup`)}>
                    {t('startup')}
                </Button>
                <Button variant={ButtonVariants.SECONDARY} size={ButtonSizes.MEDIUM} onClick={() => navigate(`/${lang}/investor`)}>
                    {t('investor')}
                </Button>
            </div>
            <div className={styles.footer}>
                <a href="https://www.linkedin.com/company/technetiumventures" target="_blank" rel="noopener noreferrer">
                    <LinkedinIcon />
                </a>
            </div>
        </div>
    );
};

export default UnderConstruction;