import { useLocation, useNavigate } from 'react-router-dom';
import styles from './Layout.module.scss';
import ChangeLangsButtons from '@/components/ChangeLangButtons/ChangeLangButtons';
import technetium from '@/assets/images/technetium_icon.png';

interface LayoutProps {
    children?: React.ReactNode;
}

const Layout = ({ children }: LayoutProps) => {
    const location = useLocation();
    const navigate = useNavigate();

    return (
        <div className={styles.container}>
            {location.pathname === '/' ? (
                <div className={styles.changeLang}>
                    <ChangeLangsButtons />
                </div>
            ) : (
                <button className={styles.homeButton} onClick={() => navigate("/")}>
                    <img src={technetium} alt="technetium" className={styles.logo} />
                </button>
            )
            }

            <div className={styles.content}>
                {children}
            </div>
        </div>
    );
};

export default Layout;
