import styles from "./ChangeLangButtons.module.scss";
import { AVAILABLE_LANGS } from "@/utils/translator/translator";
import ChangeLangButtonsController from "./ChangeLangButtons.controller";
import ES from "@/assets/icons/es_flag.png";
import EN from "@/assets/icons/en_flag.png";

function ChangeLangButtons() {
    const { changeLangButtonClicked } = ChangeLangButtonsController();

    const getLangImage = (lang: string) => {
        if (lang === 'es') {
            return ES;
        }

        if (lang === 'en') {
            return EN;
        }

        return ES;
    };

    return (
        <div className={styles.container}>
            {AVAILABLE_LANGS.map((lang) => (
                <button key={lang} onClick={changeLangButtonClicked} value={lang} type="button">
                    <img src={getLangImage(lang)} alt={lang} />
                </button>
            ))}
        </div>
    );
}

export default ChangeLangButtons;
