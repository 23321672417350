import App from './App';
import './styles/index.scss';
import { createRoot } from 'react-dom/client';
import { configureStore } from '@reduxjs/toolkit';
import rootReducer from './store/rootReducer';
import { Provider, useDispatch } from 'react-redux';
import '@/utils/translator/i18n';

const store = configureStore({ reducer: rootReducer });
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();

const container = document.getElementById('app');
const root = createRoot(container!);

root.render(
    <Provider store={store}>
        <App />
    </Provider>
);

