export const SECTORS = [
    { value: 'AdTech', label: 'AdTech' },
    { value: 'AgroTech', label: 'AgroTech' },
    { value: 'AR/VR', label: 'AR/VR' },
    { value: 'CleanTech', label: 'CleanTech' },
    { value: 'Cybersecurity', label: 'Cybersecurity' },
    { value: 'Data', label: 'Data' },
    { value: 'EdTech', label: 'EdTech' },
    { value: 'E-commerce', label: 'E-commerce' },
    { value: 'FinTech', label: 'FinTech' },
    { value: 'FoodTech', label: 'FoodTech' },
    { value: 'Gaming', label: 'Gaming' },
    { value: 'GovTech', label: 'GovTech' },
    { value: 'HealthTech', label: 'HealthTech' },
    { value: 'AI', label: 'AI' },
    { value: 'LegalTech', label: 'LegalTech' },
    { value: 'LogisticsTech', label: 'LogisticsTech' },
    { value: 'Marketplace', label: 'Marketplace' },
    { value: 'MusicTech', label: 'MusicTech' },
    { value: 'PropTech', label: 'PropTech' },
    { value: 'Robotics', label: 'Robotics' },
    { value: 'SaaS', label: 'SaaS' },
    { value: 'SpaceTech', label: 'SpaceTech' },
    { value: 'SportsTech', label: 'SportsTech' },
    { value: 'TravelTech', label: 'TravelTech' },
    { value: 'Blockchain', label: 'Blockchain' },
    { value: 'InsurTech', label: 'InsurTech' },
    { value: 'IoT', label: 'IoT' },
    { value: 'Other', label: 'Other' }
];

export const BUSINESS_MODELS = [
    { value: 'B2B', label: 'B2B' },
    { value: 'B2C', label: 'B2C' },
    { value: 'B2B2C', label: 'B2B2C' },
    { value: 'B2G', label: 'B2G' },
    { value: 'Other', label: 'Other' }
];

export const STAGES = [
    { value: 'Idea', label: 'Idea' },
    { value: 'Pre-Seed', label: 'Pre-Seed' },
    { value: 'Seed', label: 'Seed' },
    { value: 'Other', label: 'Other' }
];
