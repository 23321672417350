import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Layout from './layout/Layout';
import UnderConstruction from './pages/UnderConstruction/UnderConstruction';
import LeadForm from './pages/LeadForm/LeadForm';


const App = () => {

    return (
        <BrowserRouter>
            <Layout>
                <Routes>
                    <Route path="/" element={<UnderConstruction />} />
                    <Route path="/:lang/startup" element={<LeadForm />} />
                    <Route path="/:lang/investor" element={<LeadForm />} />
                    <Route path="*" element={<UnderConstruction />} />
                </Routes>
            </Layout>
        </BrowserRouter>
    );
};

export default App;
