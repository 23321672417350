import useTranslator from "@/utils/translator/translator";

const ChangeLangButtonsController = () => {
    const { setLang } = useTranslator();

    const changeLangButtonClicked = (event: React.MouseEvent<HTMLButtonElement>) =>
        setLang(event.currentTarget.value);

    return { changeLangButtonClicked };
};

export default ChangeLangButtonsController;
