import { useTranslation } from 'react-i18next';
import { i18n, TFunction } from 'i18next';

export const DEFAULT_LANG: string = 'es';
export const AVAILABLE_LANGS: string[] = ['es', 'en'];

export default function useTranslator(): {
    t: TFunction;
    i18n: i18n;
    lang: string;
    setLang: Function;
} {
    const lang = localStorage.getItem('lang') ?? DEFAULT_LANG;

    const { t, i18n } = useTranslation();

    function setLang(lang: string): void {
        localStorage.setItem('lang', lang);

        if (i18n.language !== lang) {
            i18n.changeLanguage(lang);
        }
    }

    setLang(lang);

    return { t: t, i18n: i18n, lang: lang, setLang: setLang };
}
