.container {
    background-color: var(--color-white);
    height: 100vh;

    .changeLang {
        display: flex;
        flex-direction: row;
        justify-content: right;
        align-items: center;
        width: 100%;
        position: absolute;
        top: 0;
        margin-top: 20px;
        margin-left: -20px;
        z-index: 999999;
    }

    .homeButton {
        background: none;
        border: none;
        cursor: pointer;
        width: 75px;
        height: auto;
        position: absolute;
        top: 15px;
        left: 15px;
        z-index: 9999999;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            // border-radius: 50%;
        }
    }

    .content {
        display: flex;
        flex-direction: row;
        justify-content: center;
        // align-items: center;
        width: 100%;
        height: 100%;
    }
}
